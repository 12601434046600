<template>
  <div class="container">
    <b-row align-h="center">
      <b-col cols="12">
        <b-card class="h-100" :title="$t('HeaderCase.UserCollaboration')">
          <div v-if="loading">
            <b-col class="text-center">
              <simple-loader />
            </b-col>
            <b-col class="text-center">
              {{ $t("general.loading") }}
            </b-col>
          </div>
          <div v-else>
            <b-row>
              <b-col>
                <h3>
                  <b>{{ caseHeader.name }}</b>
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4"
                ><strong>{{
                  $t("HeaderCase.OrganizationalUnit")
                }}</strong></b-col
              >
              <b-col cols="8">{{ caseHeader.organizationalUnitName }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="4"
                ><strong>{{ $t("HeaderCase.Owner") }}</strong></b-col
              >
              <b-col cols="8">{{ caseHeader.owner }}</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  variant="primary"
                  size="sm"
                  class="header_actions--buttons"
                  @click="modalShow = !modalShow"
                  ><b-icon-person-plus /> {{ $t("buttons.addUser") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-table
                  :items="userCollaborationFormatted"
                  :fields="fields"
                  :hover="Boolean(true)"
                >
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="deleteUser(data.item.userName)"
                      variant="danger"
                    >
                      <b-icon-trash /> {{ $t("HeaderCase.RemoveUser") }}
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal v-model="modalShow" size="lg" scrollable ok-only>
      <b-table
        sticky-header
        :items="userReadytoSelect"
        :fields="fields"
        :hover="Boolean(true)"
      >
        <template #cell(actions)="row">
          <b-button
            size="sm"
            class="mr-1"
            @click="addUser(row.item.userName)"
            variant="primary"
          >
            <b-icon-plus />
          </b-button> </template
      ></b-table>
    </b-modal>
  </div>
</template>
<style scoped>
.header_actions--buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      modalShow: false,
    };
  },
  methods: {
    ...mapActions("Cases", [
      "getCaseHeader",
      "getUserCollaboration",
      "postUserCollaboration",
      "deleteUserCollaboration",
    ]),
    ...mapActions("User", ["getUsers"]),
    addUser(userName) {
      const { idCase } = this.$route.params;
      const that = this;
      this.postUserCollaboration({ api: this.$api, idCase, userName }).catch(
        (err) => {
          that.$bvToast.toast(err, {
            variant: "danger",
            title: that.$t("buttons.addUser"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      );
    },
    deleteUser(userName) {
      const { idCase } = this.$route.params;
      const that = this;
      this.deleteUserCollaboration({ api: this.$api, idCase, userName }).catch(
        (err) => {
          that.$bvToast.toast(err, {
            variant: "danger",
            title: that.$t("HeaderCase.RemoveUser"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      );
    },
  },
  computed: {
    ...mapGetters("Cases", ["caseHeader", "userCollaboration"]),
    ...mapGetters("User", ["users"]),
    fields() {
      return [
        { key: "userName", label: this.$t("general.userName") },
        { key: "name", label: this.$t("HeaderCase.name") },
        { key: "mail", label: this.$t("HeaderCase.mail") },
        { key: "actions", label: this.$t("HeaderCase.actions") },
      ];
    },
    userCollaborationFormatted() {
      const userFormatted = this.userCollaboration.map((el) => {
        const user = this.users.find((element) => {
          return element.userName === el.userName;
        });
        if (!user) return;
        return {
          userName: el.userName,
          mail: user.mail,
          name: user.name,
        };
      });
      return userFormatted || [];
    },
    userReadytoSelect() {
      return this.users.filter((el) => {
        const user = this.userCollaboration.find((element) => {
          return element.userName === el.userName;
        });
        if (!user) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.loading = true;
    const { idCase } = this.$route.params;
    const that = this;
    if (!idCase) return;
    this.getCaseHeader({
      api: this.$api,
      session: this.$session,
      idCase,
    })
      .then((res) => {
        that
          .getUsers({
            api: that.$api,
            enabled: true,
            idOU: res.data.idOrganizationalUnit,
          })
          .catch((err) => {
            console.log(err);
          });
        that
          .getUserCollaboration({
            api: this.$api,
            idCase,
          })
          .catch((err) => {
            that.$bvToast.toast(err, {
              variant: "danger",
              title: that.$t("buttons.addUser"),
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$router.back();
          });
      })
      .finally(() => {
        that.loading = false;
      });
  },
};
</script>
